import React from 'react';

const Tab = ({ title, active, setactiveTab, settab }) => {
    return (
        <div
            className={`tab ${active ? 'active' : ''}`}
            onClick={() => {
                setactiveTab(title);
                settab(title);
            }}
        >
            {title}
        </div>
    );
};

export default Tab;
