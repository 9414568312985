import React from 'react';
import * as Icons from 'react-feather';
const TABS = [ 'Overview', 'Education', 'Skills', 'Projects', 'Github' ];

const CONTENTS_FACTS = {
    Overview: {
        'Looking For':
            'Full-Time position for Software Developer/Engineer.',
        'About Me':
            'A proficient software developer with hands-on development experience in various personal, group, and enterprise applications. A cloud-technology enthusiast with passion for microservice architecture and cloud-service architecture.',
        'Good At': 'Problem Solving, Time Management, Communication, Creative ideas',
        Address: 'Lafayette, LA',
        Interests: 'Watching soccer, playing soccer, gaming',
        '': (
            <div className='resume-download'>
                <a href='SushantBaskotaresume.pdf' target='_blank'>
                    My Resume<Icons.ArrowUpRight />
                </a>
            </div>
        )
    }
};

const PROJECTS = [
    {
        name: 'Windows Portfolio',
        icon: './icons/windowsXP/logo.png',
        desc: 'This is a Windows XP themed Portfolio website I made by using just SASS and React. ',
        link: 'https://windows-portfolio.web.app/'
    },
    {
        name: 'Corona in Nepal',
        icon: './icons/corona.svg',
        desc:
            'This app updates you with the latest COVID-19 data in Nepal which was made using D3.js along with React and Node.js Express server on the backend.',
        link: 'https://coronainnepal.com'
    },
    {
        name: 'Recipe App',
        icon: './icons/projects/recipe.png',
        desc:
            'This is a MERN stack app that allows the user to create their own recipes and choose recipes from the extensive amount of recipes from spoonacular API',
        link: 'https://recipe-app-softwaredev.web.app/'
    },
    {
        name: 'Rate My Professors- Alexa App (Vandy Hacks)',
        icon: './icons/alexa.png',
        desc:
            'This Alexa app allows you to ask about a certain professor from a certain university and it searches the data in ratemyprofessor.com and gives you the corresponding ratings. ',
        link: 'https://github.com/sushantbaskota2/YayorStayAway-AlexaSkill'
    },
    {
        name: 'SkinCancer MNIST Classification',
        icon: './icons/tensor.png',
        desc: 'Used ResNet-50 in TensorFlow to predict 7 different types of skin cancer lesions. ',
        link: 'https://github.com/sushantbaskota2/skin-cancerMNIST'
    },
    {
        name: 'Corona Facts -Alexa Skill',
        icon: './icons/corona.svg',
        desc:
            'This app was made using VoiceFlow and it gives you the number of cases of Corona as per your request to Alexa.',
        link: 'https://github.com/sushantbaskota2/CoronaFacts-AlexaSkill'
    }
];
export { CONTENTS_FACTS, TABS, PROJECTS };
